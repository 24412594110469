<template>
  <IFrameWithForm :form="paymentRedirectionForm"></IFrameWithForm>
</template>

<style>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import IFrameWithForm from '../../../panels/IFrameWithForm.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components : {
    IFrameWithForm
  }
})
export default class Payment extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();

  paymentRedirectionForm = this.$store.state.eurofiscalis.workers.register.payment;

  beforeCreate() {
    if(!this.$store.state.eurofiscalis.workers.register.payment.action) {
      // We need to redirect to register.
      this.$router.push("./register").catch(err => {});
    }
  }

  mounted() {
    
  }

  onRegisterFormSubmit(evt:Event) {
    
  }
  
}
</script>