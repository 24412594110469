<template>
  <div id="iframeWithForm">
    <form target="iframe-with-form" :action="form.action" ref="theForm">
      <input v-for="(param, index) in form.parameters" :key="index" :name="param.name" :value="param.value" type="hidden" />
    </form>
    <iframe name="iframe-with-form"></iframe>
  </div>
</template>

<style scoped>
  #iframeWithForm {
    height:100%;
    font-size: 0px;
  }
  iframe {
    width:100%;
    height:100%;
    border:0px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';

@Component({
})
export default class IFrameWithForm extends Vue {
  
  @Prop({
    type: Object,
    required: true
  }) readonly form: any

  mounted() {
    // @ts-ignore
    this.$refs.theForm.submit();
  }
  
  
}
</script>